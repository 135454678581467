.wrapper {
    display: flex;
    width: 90%;
    /* height: 300px; */
    border: 2px solid rgb(219, 219, 219);
    border-radius: 3px;
    padding: 50px;
    margin-bottom: 30px;
}

.child_content {
    width: 80%;
    margin-right: 20px;
}

.button_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.editBtn {
    width: 150px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background-color: #f4f4f4;
}

.editBtn:hover {
    cursor: pointer;
}