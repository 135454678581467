.profileAccountStatus_wrapper {
    /* background-color: rgb(222, 245, 220); */
    padding: 20px;
    color: rgb(70, 70, 70);
}

.header {
    margin-bottom: 20px;
    font-size: 18px;
}

.account_status > p {
    margin: 0 0 5px 10px
}