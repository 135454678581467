* {
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  list-style: none;
}


:root {
  --bg: rgb(255, 255, 255);
  --bg-accent: rgb(247, 247, 247);
  --text-color: #222;
  --nav-size: 60px;
  --redBtn: #e87575;
  --redBtnHover: #e43131;
  --border: 1px solid rgb(228, 228, 228);
  --border-radius: 3px;
  --box-shadow: 0px 2px 9px 0px rgb(238, 238, 238);
  --speed: 200ms
}

.redBtn {
    margin: 15px 0;
    padding: 8px 40px;
    border-radius: 20px;
    border: none;
    background-color: #e87575;
    color: white;
    font-weight: 600;
    font-size: 14px;
    max-width: 200px;
}

.redBtn:hover {
  cursor: pointer;
  background-color: #e43131;
  
}


.dd_wrapper {
  box-sizing: border-box;
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-60%);
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  overflow: hidden;
  background-color: var(--bg);
  transition: height var(--speed);
  z-index: 100;
}

.dd_menu-item {
  color: rgb(75, 75, 75);
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background-color var(--speed);
  padding: 10px;
}

.dd_menu-item:hover {
  background-color: var(--bg-accent);
  cursor: pointer;
}

.menuLink {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
/* compined with flex-box on parent this will push the element to the right and everything else to the left*/
.icon-right {
  margin-left: auto;
}

.menu {
  width: 100%;
}


/* inside menu nest 2 */
.menu-third-enter {
  /* position: absolute; */
  transform: translateX(110%);
  

}
.menu-third-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;

}
/* .menu-third-exit {
  position: absolute;

} */
.menu-third-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;

}






.overlayMenu-fade-enter {
  /* position: absolute; */
  transform: translateY(-100%);
  

}
.overlayMenu-fade-enter-active {
  transform: translateY(0%);
  transition: all var(--speed) ease;

}
.overlayMenu-fade-exit {
  transform: translateY(-100%);
  transition: all var(--speed) ease;
  position: absolute;

}
.overlayMenu-fade-active {
  transform: translateY(100%);
  transition: all var(--speed) ease;

}

.error_message_small {
  font-weight: 600;
  color: red;
  font-size: 14px;
}