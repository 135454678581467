.profileShowreel_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.header {
    font-size: 22px;
    color: rgb(78, 77, 77);
    margin-bottom: 40px;
}

.header > span {
    padding: 0 50px;
}
.header > span:hover {
    cursor: pointer;
}

.showreel_content {
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 800px;
    height: 220px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 20px;
    box-shadow: inset 0px 0px 7px 0px rgba(142, 142, 142, 0.35);
    background-color: #f1f6fc8c;
}

.active {
    text-decoration: underline;
    text-decoration-color: rgb(216, 45, 45);
}
