.formElement {
    width: 250px;
    margin: 25px 50px 50px 50px;
}

.formElement > h2 {
    margin-bottom: 20px;
}

.submitBtn {
    margin: 8px auto;
    display: block;
    padding: 8px;
    background-color: #e85f5f;
    border: none;
    color: white;
    font-weight: 800;
    border-radius: 8px;
    font-size: 12px;
    width: 180px;
}

.submitBtn:hover {
    cursor: pointer;
    color: white;
    background-color: #de3333;
}

.authenticateWrapper {
    /* width: 180px; */
}

.authenticateHeader {
    margin: 10px 20px;
    font-size: 16px;
    color: grey;
}

.authenticateParagraph {
    margin: 10px 20px;
    color: grey;
    /* margin-bottom: 10px; */
}