.wrapper {
    max-width: 400px;
    /* height: 250px; */
    margin: 0 20px;
    /* padding: 0 30px; */
    box-sizing: border-box;
}

.formElement {
    width: 300px;
    margin-bottom: 50px;
}

.submitBtn {
    margin: 8px auto;
    display: block;
    padding: 8px;
    background-color: #e85f5f;
    border: none;
    color: white;
    font-weight: 800;
    border-radius: 8px;
    font-size: 12px;
    width: 180px;
}

.submitBtn:hover {
    cursor: pointer;
    color: white;
    background-color: #de3333;
}

.errorMessage {
    font-style: italic;
}

@media screen and (max-width: 350px) {
    .formElement {
        width: 200px;
    }
    
}