.wrapper {
    width: 100%;
    margin-bottom: 20px;
}

.bottomLine {
    width: 80%;
    padding: 30px 0 20px 20px;
    border-bottom: 2px solid rgb(228, 228, 228);
}

.link {
    font-size: 25px;
    color: rgb(63, 63, 63);
}

.arrow {
    display: inline-block;
    color: rgb(63, 63, 63);
    margin: 0 10px;
    font-size: 20px;
    font-weight: 500;
}