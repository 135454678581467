.wrapper {
display: flex;
}

.centerWrapper {
    width: 100%;
}

@media screen and (max-width: 770px) {
    .wrapper {
        display: flex;
    flex-direction: column;
    align-items: center;
    }
}