
a {
    text-decoration: none;
    color: rgb(43, 43, 43);
}

li {
    margin: 15px 10px;
}

.wrapper {
    display: inline-block;
    width: 300px;
    padding: 100px 0 0 40px;
}
.newCollection {
    display: inline-block;
    border: 1px solid grey;
    border-radius: 20px;
    padding: 5px 50px;
}

.newCollection:hover {
    background-color: rgb(247, 95, 95);
    color: white;
    cursor: pointer;
    
    border: 1px solid rgb(247, 95, 95);
}

@media screen and (max-width: 770px) {
    .newCollection {
        display: inline-block;
        border: 1px solid grey;
        border-radius: 15px;
        padding: 10px 50px;
    }
}
