.wrapper {
    max-width: 700px;
    padding-bottom: 50px;
}

.logo_wrapper {
    width: 25%;
    margin: 0 auto;
}


.header {
    text-align: center;
    font-weight: 100;
    font-size: 40px;
    margin-bottom: 25px;
}

.content {
    width: 80%;
    margin: 0 auto;
}

.buttonsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 50px;
}

.registerBtn {
    padding: 8px;
    background-color: #e85f5f;
    border: none;
    color: white;
    font-weight: 800;
    border-radius: 8px;
    font-size: 12px;
    width: 180px;
}

.loginBtn {
    padding: 8px;
    background-color: #f0f0f0;
    border: 1px solid #b6b5b5;
    color: #7c7c7c;
    font-weight: 800;
    border-radius: 8px;
    font-size: 12px;
    width: 180px;
}

.registerBtn:hover {
    cursor: pointer;
    background-color: #ce5454;
}

.loginBtn:hover {
    cursor: pointer;
    background-color: #e7e7e7;
    color: #707070;
}

@media screen and (max-width: 415px) {
    .buttonsWrapper {
        flex-direction: column;
        align-items: center;
    }

    .registerBtn {
        margin-bottom: 10px;
    }
    .registerBtn, .loginBtn {
        width: 70%;
        height: 40px;
    }
}
