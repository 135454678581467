.valid {
    background-color: rgb(190, 241, 190);
}
.invalid {
    background-color: rgb(243, 225, 225);
}

.validationError {
    /* margin: 5px 0; */
    font-size: 12px;
    font-style: italic;
    color: rgb(128, 128, 128);
}


.inputfield {
    width: 100%;
    height: 30px;
    border: 2px solid #aabfd5;
    border-radius: 3px;
    padding-left: 10px;
    box-sizing: border-box;
    /* background-color: #f8fcff; */
    margin: 8px 0;
}