.menuSection {
    height: 30px;
    width: 100%;
    position: absolute;
}
.wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* display: inline-block; */
    position: relative;
    margin: 10px;
    box-shadow: 1px 1px 5px 0px rgb(180, 179, 179);
    border-radius: 5px;
    transition: all 0.3s ease;
}

.small {
    width: 200px;
    height: 250px;
}

.large {
    width: 400px;
    height: 500px;
    
}
 
 .wrapper:hover {
     cursor: pointer;
 }

.title {
    font-size: 15px;
   margin: 0px 20px 10px 20px;
}

.descriptionWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    margin: 0 auto;
}

.description {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 50%;
    font-size: 14px;
}

.descriptionSmall {
    height: 0;
}
.descriptionLarge {
    height: 300px;
}

/* .expand {
    position: absolute;
    bottom: 0;
    height: 30px;
    text-align: center;
    width: 100%;
    font-weight: 600;
} */

.image {
    /* width: 100px; */
    display: block;
    margin: 10px auto;
    transition: all 0.3s ease;
}
.imageSmall {
    height: 150px;
}
.imageLarge {
    height: 300px;
}

.btnWrapper {
    /* height: 100px; */
    display: flex;
    align-items: center;
}
.linkBtn {
    background-color: #da4444;
    color: white;
    font-weight: 600;
    padding: 8px 30px;
    border-radius: 5px;
    margin: 10px;
}
