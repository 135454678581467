.CenterContent {
    Display: inline-block;
    width: 100%;
    height: 800px;
}

.formElement {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 50px;
}

.checkbox_wrapper {
    display: flex;
    align-items: center;
}

.label {
    font-size: 12px;
    margin-right: 15px;
}

.button {
    margin: 15px 0;
    padding: 8px 30px;
    border-radius: 20px;
    border: none;
    background-color: #ffffff;
    border: 1px solid;
    color: #8d8d8d;
    /* max-width: 50px; */
}

.button:hover {
    cursor: pointer;
    background-color: var(--redBtn);
    color:white;
    border: 1px solid var(--redBtn);
}

.button:active {
    background-color: var(--redBtnHover);
    color:white;
    border: 1px solid var(--redBtnHover);
}

@media screen and (max-width: 375px) {
    .formElement {
        margin: 0 ;
    }

    .button {
        /* margin: 15px 0; */
        font-size: 10px;
        padding: 8px 20px;

    }
    
}
