.wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100 vh;
}
.card {
    display: inline-block;
    width: 600px;
    height: 800px;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    object-fit: scale-down;
    background-color: grey;
}

.card > img {
    height: 100%;
}