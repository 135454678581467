.menu-slideToLeft-enter {
    position: absolute;
    transform: translateX(-110%);
    
  
  }
  .menu-slideToLeft-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  
  }
  .menu-slideToLeft-exit {
    position: absolute;
  
  }
  .menu-slideToLeft-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  
  }