
.main-wrapper {
  display: flex;
}

.loading {
  display: inline-block;
  font-size: 60px;
  color: rgb(161, 161, 161);
  margin: 100px auto;
}

