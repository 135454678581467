.wrapper {
    display: flex;
    flex-wrap: wrap;
}

.delete_popup_wrapper {
    margin: 20px 50px;
    display: flex;
    flex-direction: column;
}

/* Edit popup */
.edit_popup_wrapper {
    margin: 0 30px;
}

.edit_collection_form {
    display: flex;
    flex-direction: column;
    min-width: 300px;
}
.edit_collection_form > label {
    font-weight: 600;
    margin: 20px 0;
}

.edit_title {
    display: block;
    width: 90%;
    border: none;
    border-left: 2px solid #4da5c3db;
    padding-left: 10px;
    padding-top: 5px;
    font-weight: 700;
    margin-bottom: 10px;
}

.edit_description {
    display: block;
    width: 90%;
    border: none;
    padding-left: 10px;
    padding-top: 5px;
    border-left: 2px solid #4da5c3db;
    width: 375px;
    height: 125px;
}

/* End of edit popup */