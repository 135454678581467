.profile_wrapper {
    /* background-color: aliceblue; */
    width: 80%;
    margin: 40px auto;
    min-width: 800px;
}

.profile_mainContent {
    display: flex;
    justify-content: center;
}

.profile_status {
    margin-right: 100px;
}