.card {
    display: inline-block;
    width: 30vw;
    height: calc(30vw * 1.5);
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    margin-bottom: 5px;
    background-color: grey;
    border-radius: 5px;

}

.card > img {
    height: 100%;
}