.wrapper {
    /* width: 500px; */
    padding: 20px 50px 40px 50px;
}

.sidenote {
    margin: 15px 0;
    font-size: 14px;
}

.formElement {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inputfield {
    height: 30px;
    border: 1px solid #b8d1ee;
    padding-left: 8px;
    font-weight: 600;
    background-color: #f8fcff;
    /* color: #c8c9cc; */
    margin: 10px 0;
    border-radius: 3px;
}

.inputfield::placeholder {
    font-weight: 100;
    font-style: italic;
}

.sendBtn {
    width: 150px;
    text-align: center;
    margin: 15px 0;
    padding: 10px 30px;
    border-radius: 20px;
    border: none;
    background-color: #e25757;
    color: white;
    font-weight: 600;
    font-size: 14px;
}

.sendBtn:hover {
    background-color: crimson;
    cursor: pointer;
}

/* Preview */
.preview_wrapper {
    padding: 0 50px 50px 50px;
}

.preview_header_wrapper {
    margin-bottom: 20px;
}

.preview_content {
    display: flex;
}
.formElement {
    width: 400px;
}

.preview_input {
    display: block;
    width: 90%;
    border: none;
    border-bottom: 2px solid #4da5c3db;
    padding-bottom: 5px;
    font-weight: 700;
    margin-bottom: 20px;
}

.preview_textarea {
    display: block;
    width: 90%;
    border: none;
    border-bottom: 2px solid #4da5c3db;
    width: 375px;
    height: 125px;
}

.preview_image_wrapper {
    width: 250px;
    margin-left: 20px;
}

.image {
    width: 100%;
}

.no_image {
    width: 250px;
    border: 0.5px solid black;
    margin-left: 20px;
}
/* End of review */

/* Error message */
.error_message {
    font-weight: 600;
    color: rgb(214, 30, 30);
}
/* End of error message */

/* Sucess */
.success_message {
    margin: 10px 0 20px 0;
}

/* End of success */


@media screen and (max-width: 480px) {
    .inputfield {
        width: 60%;
        font-size: 10px;
    }
}

