.wrapper {
    max-width: 1000px;
    margin: 50px auto;
    width: 60%;
    min-width: 300px;
}

.header_wrapper {
    color: #4e4e4e;
    
}

.header_text {
    font-size: 40px;
}

.bottom_line {
    margin: 15px 0 10px 0;
    border-bottom: 2px solid rgb(228, 228, 228);
}

.bread_text {
    font-size: 18px;
}

/* .content_wrapper {

} */

.activate_chrome_button {
    margin: 20px 0;
    background-color: #317CBD;
    border: none;
    padding: 10px;
    border-radius: 3px;
}

.activate_chrome_button:hover {
    cursor: pointer;
    background-color: rgb(95, 157, 211);
 
}
.activate_chrome_button:active {
    cursor: pointer;
    background-color: rgb(161, 161, 161);
 
}

.activate_chrome_button > a {
    color: white;
    font-weight: 600;
}

@media screen and (max-width: 380px) {
    .header_text {
        font-size: 20px;
    }

    .bread_text {
        font-size: 12px;
    }
    
    .activate_chrome_button {
        margin: 0;
    }
}