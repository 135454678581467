.popup_wrapper {
    margin: 20px;
}

.header {
    margin-bottom: 20px;
}

.info_text {
    font-style: italic;
}

.label {
    display: block;
    margin: 10px 0 10px 0;
}

.inputfiled {
    height: 30px;
    border: solid 2px #a6c7e9;
    margin-right: 40px;
    border-radius: 3px;
    width: 200px;
    padding-left: 5px;
}

.submit_btn {
    display: block;
    margin: 10px 0 10px 0;

    padding: 8px;
    background-color: #e85f5f;
    font-weight: 800;
    border-radius: 8px;
    font-size: 12px;
    width: 180px;


    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background-color: #f4f4f4;
    
}

.submit_btn:hover {
    cursor: pointer;
    border: 1px solid #e85f5f;
    background-color: #e85f5f;
    color: white;

}
