.wrapper {
    /* width: 100%;
    height: 100%;
    background-color: azure;
    position: relative; */

    height: 30px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.192);
    position: relative;
    border-radius: 5px 5px 0 0;

    /* transform: translateY(-10%); */
    /* transition: translateY 1s ease; */
}

.menuBar {
    position: absolute;
    top: 0;
    right: 0;
}

.menuItemsWrapper {
    display: flex;
    align-items: center;
}

.menuItemsWrapper > span {
    margin: 5px 10px;
    color: white;
    font-weight: 600;
}

.menuItemsWrapper > span:hover {
    cursor: pointer;
}

