.wrapper {
    width: 90%;
    margin: 10px auto;
    max-width: 800px;
}

.header {
    color: #525252;
    margin-bottom: 10px;
}

.content_preamble {
    font-size: 18px;
}

.content_body {
    font-size: 13px;
    margin-top: 10px;

}

@media screen and (max-width: 380px) {
    .header {
        font-size: 20px;
    }
    .content_preamble {
        font-size: 16px;
    }
    
    .content_body {
        font-size: 12px;
    }
}