.header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px rgb(228, 228, 228) solid;
    padding-left: 80px;
    padding-right: 80px;
}

.logo_wrapper {
height: 30px;
width: 50px;

}

.nav_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header ul {
    display: flex;
    align-items: center;
}

.header li {
    display: inline-block;
    margin: 0 10px;
}


.header a {
    text-decoration: none;
    color: rgb(75, 75, 75);
}

.header a:hover {
    color: grey;
}

.profilePic {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.line {
    margin-left: 10px;
    height: 30px;
    width: 25px;
    border-left: 2px solid rgb(228, 228, 228);
}


@media screen and (max-width: 770px) {
    .header {
        padding-left: 40px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 600px) {
    .hide_at_600px {
        display: none;
    }
}
