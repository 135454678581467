.pu_background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 200;
}


.pu_window {
    /* position: relative; */
    min-width: 100px;
    min-height: 200px;
    background-color: white;
    border-radius: 3px;
    margin: 5px;

}

.pu_topbar {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 30px;
    border-radius: 3px 3px 0 0;
    padding: 10px;
    background-color: rgb(255, 255, 255);

}

.children_wrapper {
  margin: 10px;
}

.pu_closeBtn {
    font-size: 12px;
    color: rgb(119, 119, 119);

}

.pu_closeBtn:hover {
    color: black;
    cursor: pointer;
}
