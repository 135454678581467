.image {
    margin-bottom: 50px;
    width: 200px;
}

.image:hover {
    cursor: pointer;
}

.profilePicture_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.change_profile {
    font-style: italic;
}

.change_profile:hover {
    color: grey;
}