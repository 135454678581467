.NewsCard_wrapper {
    margin: 10px 0 10px 0;
    box-shadow: 1px 2px 11px 1px #d4d4d4;
    border-radius: 2px;
}

.headers_wrapper {
padding: 10px;
}

.headers_wrapper > h1 {
    margin-bottom: 10px;
    color: #525252;
}

.headers_wrapper > p {
    color: grey;
}

.updates {
    margin-bottom: 20px;
}

.updates > ul li {
    color: grey;
}

.updates_bar {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #319dbd;
    color: #f0f0f0;
    font-weight: 900;
    padding-left: 10px;
    margin-top: 20px;
}

.updates_bar:hover {
    cursor: pointer;
}

.updates_bar:active {
    cursor: pointer;
    background-color: #237e99;

}

.show {
    max-height: 500px;
    transition: max-height 1s ease;
}

.hide {
    transition: max-height 0.2s ease;
    overflow: hidden;
    max-height: 0px;
}

.date {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    padding-bottom: 10px;
    font-style: italic;
    color: grey;
}