.mainWrapper {
    max-width: 500px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 90%;
}

.formElement {
    width: 90%;
}

.inputfield {
    display: block;
    width: 100%;
    border: 2px solid #cbdae5;
    border-radius: 3px;
    padding: 5px 10px;
    box-sizing: border-box;
    font-weight: 600;
    margin: 15px 0;
}


.description {
    width: 100%;
    border: 2px solid #cbdae5;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 5px 8px;
    font-family: sans-serif;
    height: 70px;
}

.button {
    margin: 15px 0;
    padding: 8px 40px;
    border-radius: 20px;
    border: none;
    background-color: #e87575;
    color: white;
    font-weight: 600;
    font-size: 14px;
}

.button:hover {
    cursor: pointer;
    background-color: #f33f3f;
}

.warning {
    color: rgb(196, 57, 57);
}

.successWrapper {
    width: 300px;
    margin-bottom: 40px;
}
.success {
    color: rgb(37, 173, 37);
    margin: 10px 0;
}

.successMessage {
    color: grey;
    margin: 30px 0;
}

.successBtn {
    margin: 15px 0;
    padding: 8px 40px;
    border-radius: 20px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 14px;
    background-color: #f33f3f;
    width: 70px;

    display: block;
    text-align: center;
    margin: 0 auto;

}

.successBtn:hover {
    cursor: pointer;
}