.NewsWrapper_wrapper {
    width: 50%;
    min-width: 300px;
    max-width: 1000px;
    margin: 50px auto;
}

.header_Wrapper > h1 {
    border-bottom: 2px solid #e4e4e4;
    font-style: italic;
    padding-bottom: 10px;
    margin-bottom: 5px;
    color: #525252;
}

.header_Wrapper > p {
    font-size: 1.2em;
    color: grey;
    margin: 20px 0 10px 0;
}

.newscard_wrapper {
    margin-top: 30px;
}
