
.menuSection {
    height: 30px;
}
.collectionCardWrapper {
    width: 250px;
    height: 150px;
    box-shadow: -1px 2px 9px 0px rgb(216, 216, 216);
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.link {
    display: inline-block;
    text-decoration: none;
    color: black;

    width: 100%;
    height: 70%;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}