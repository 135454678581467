.wrapper {
    display: flex;
}

.header {
    font-size: 22px;
    margin-right: 20px;
}

.civil_status_wrapper {
    margin: 0 30px;
    display: block;
}

/* form */
.label {
    display: block;
    font-weight: 600;
    margin: 5px 0;
    font-size: 12px;
    color: rgb(39, 39, 39);
}

.labelHeader {
    color: rgb(54, 54, 54);
    display: block;
    font-weight: 600;
    margin: 5px 0;
}

.inputfield {
    height: 25px;
    width: 100%;
    border: 2px solid #c4c2c2;
    border-radius: 3px;
    padding-left: 5px;

    color: #535151;
    font-weight: 600;
    font-size: 15px;
}

.inputfield:focus {
    background-color: rgb(246, 251, 255);
}

.inputfield::placeholder { 

}

.submit_btn {
    color: black;
    margin-top: 10px;
    width: 150px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background-color: #f4f4f4;
    transition: background-color 0.5s ease;
    transition: color 0.5s ease;
}

.submit_btn:hover {
    cursor: pointer;
}

.savedSuccess {
   background-color: #368436;
   color: white;
}

.savedError {
    background-color: rgb(224, 44, 44);
    color: white;
}
