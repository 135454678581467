.wrapper {
    flex-wrap: wrap;
    display: flex;
    width: 100%; 
}

.popupWrapper {
    margin: 0 50px;
    width: 300px;
}

/* Edit popup */
.edit_popup_wrapper {
    padding: 0 50px 50px 50px;
}

.edit_header_wrapper {
    margin-bottom: 20px;
}

.edit_content {
    display: flex;
}

.edit_item_form {
    width: 400px;
}

.edit_item_form > label {
    font-weight: 600;
    margin: 20px 0;
}

.edit_title {
    display: block;
    width: 90%;
    border: none;
    border-bottom: 2px solid #4da5c3db;
    padding-bottom: 5px;
    font-weight: 700;
    margin-bottom: 20px;
}

.edit_description {
    display: block;
    width: 90%;
    border: none;
    border-bottom: 2px solid #4da5c3db;
    width: 375px;
    height: 125px;
}

.edit_image_wrapper {
    width: 250px;
    margin-left: 20px;
}

.image {
    width: 100%;
}

.no_image {
    width: 250px;
    border: 0.5px solid black;
    margin-left: 20px;
}
/* End of edit popup */