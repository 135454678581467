.wrapper {
    margin: 20px 0;
}

.wrapper:hover {
    cursor: pointer;
}

.header_bar {
    display: flex;
    background-color: #F3F3F3;
    padding: 10px;
    font-style: normal;
    font-size: 18px;
}

.header {
    display: inline-block;
}

.arrow_wrapper {
    display: flex;
    margin-right: 10px;
}

.point_up {
    transform: rotateX(0deg);
    transition: transform 0.2s;
}

.point_down {
    transform: rotateX(180deg);
    transition: transform 0.2s;
}

.left_line:before {
    display: inline-block;
    height: 50%;
    content: "";
    border-left: 2px solid grey;
    transform: rotate(45deg);
}
.right_line:before {
    display: inline-block;
    margin-left: 5px;
    height: 50%;
    content: "";
    border-right: 2px solid grey;
    transform: rotate(-45deg);
}

.header_bar:active {
    background-color: rgb(202, 202, 202);
}


.content_wrapper {
    background-color: #F3F3F3;
    overflow: hidden;
}

.show_details {
    max-height: 500px;
    transition: all 0.8s ease;
}

.hide_details {
    max-height: 0;
    transition: all 0.5s linear;
}

.content_bread_text {
    font-size: 13px;
    padding: 0 10px 10px 10px;
    font-style: normal;
}

.content_descriptive_text {
    padding: 0 10px 20px 10px;
    font-size: 12px;
}

@media screen and (max-width: 380px) {
    .header_bar {
        font-size: 14px;
    }

    .content_bread_text {
        font-size: 12px;
    }
    
    .content_descriptive_text {
        font-size: 11px;
    }
    
}