.wrapper {
display: flex;
}


@media screen and (max-width: 770px) {
    .wrapper {
        display: flex;
    flex-direction: column;
    align-items: center;
    }
}