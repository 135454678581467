.profileCivilStatus_wrapper {
    /* background-color: beige; */
    padding: 20px;
    color: rgb(70, 70, 70);
}

.username {
    margin-bottom: 20px;
}

.civil_status > p {
    margin: 0 0 5px 10px
}